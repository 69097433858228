import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found.component';
import {RouterModule} from '@angular/router';
import {notFoundRoutes} from './not-found.routes';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [NotFoundComponent],
  exports: [NotFoundComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(notFoundRoutes),
    TranslateModule,
  ]
})
export class NotFoundModule {
}
