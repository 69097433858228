<div id="mySidenav" class="overlay">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"><i class="fas fa-times"></i></a>
  <div class="overlay-content">
    <a (click)="route('home')"> <img class="logo-img" src="../../../assets/img/ronalogo.png"></a>
    <a (click)="route('home')" translate>navbar.home</a>
    <a (click)="route('currency')" translate>navbar.liveMarket</a>
    <a (click)="route('gold')" translate>navbar.infoSociety</a>
    <a (click)="route('info')" translate>navbar.aboutUs</a>
    <a (click)="route('uyum')" translate>navbar.conformity</a>
    <a (click)="route('comm')" translate>navbar.contact</a>
    <a (click)="changeLang()">
      <img class="flag" src="assets/img/tr-flag.png">
      <img class="flag" src="assets/img/eng-flag.png">
    </a>
  </div>
</div>
<div id="main" class="header">
  <span class="click-icon" (click)="openNav()"><i class="fas fa-bars"></i></span>
  <a (click)="route('home')"> <img class="logo-img" src="../../../assets/img/ronalogo.png"></a>
</div>
