import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTING} from '../../shared/routing';
import {TranslateServices} from '../../_services/translate.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  time = new Date();
  lang = 'tr';
  selectedValue = 'tr';

  constructor(private router: Router,
              private translateServices: TranslateServices) {
  }

  ngOnInit(): void {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  route(page): void {
    switch (page) {
      case 'home': {
        this.router.navigateByUrl(ROUTING.HOME);
        break;
      }
      case 'currency': {
        this.router.navigateByUrl(ROUTING.CURRENCY);
        break;
      }
      case 'gold': {
        this.router.navigateByUrl(ROUTING.GOLD);
        break;
      }
      case 'info': {
        this.router.navigateByUrl(ROUTING.INFORMATION);
        break;
      }
      case 'comm': {
        this.router.navigateByUrl(ROUTING.CONTACT);
        break;
      }
      case 'uyum': {
        this.router.navigateByUrl(ROUTING.UYUM);
        break;
      }
    }

  }

  closeNav(): void {
    document.getElementById('mySidenav').style.width = '0';
  }

  openNav(): void {
    document.getElementById('mySidenav').style.width = '250px';
  }

  changeLang() {
    if (this.lang === 'eng') {
      this.lang = 'tr';
    } else {
      this.lang = 'eng';
    }
    this.translateServices.changeLanguage(this.lang);
  }
}
