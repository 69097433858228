<button class="open-button" (click)="openWarning()"><i class="fas fa-exclamation"></i></button>
<div id="myNav" class="overlay">
  <h3 style="text-align: center;">Yasal Uyarı</h3>
  <div>
    Burada yer alan yatırım bilgi, yorum analiz ve tavsiyeleri yatırım danışmanlığı kapsamında değildir. Yatırım
    danışmanlığı hizmeti; aracı kurumlar, portföy yönetim şirketleri, mevduat kabul etmeyen bankalar ile müşteri
    arasında
    imzalanacak yatırım danışmanlığı sözleşmesi çerçevesinde sunulmaktadır. Burada yer alan yorum ve tavsiyeler, yorum
    ve
    tavsiyede bulunanların kişisel görüşlerine dayanmaktadır. Bu görüşler mali durumunuz ile risk ve getiri
    tercihlerinize
    uygun olmayabilir. Bu nedenle, sadece burada yer alan bilgilere dayanılarak yatırım kararı verilmesi
    beklentilerinize
    uygun sonuçlar doğurmayabilir.
  </div>
  <button type="button" class="btn cancel" (click)="closeNav()">Kapat</button>
  <p>
    Powered by <a style="margin-left: 5px" href="http://www.aifasoft.com/" target="_blank">AIFASOFT</a>
  </p>
</div>
