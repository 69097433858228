import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-warning-component',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {
  noButton = 'Kapat';

  constructor(public dialogRef: MatDialogRef<WarningComponent>) {
  }

  ngOnInit(): void {
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
