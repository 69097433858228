import {Component, DoCheck, OnInit} from '@angular/core';
import {TranslateServices} from '../../_services/translate.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, DoCheck {
  lang: string;

  constructor(private translateService: TranslateServices) {
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.lang = this.translateService.getLanguage();
  }
}
