<div class="container" style="margin-top:10em ;">
  <p style="color:white; " translate>complience.mainText </p>

  <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/Musteri-kabul-politikasi.pdf"><span style="color:#9d8b54"
                                                                                                   translate>complience.customer</span></a></em>
  </p>
  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/Customer-Acceptance-Policy.pdf"><span
    style="color:#9d8b54" translate>complience.customer</span></a></em>
  </p>
  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/independent_auditors_report.pdf"><span
    style="color:#9d8b54" translate>complience.independent</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../../assets/docs/ENGKYC1.pdf"><span
    style="color:#9d8b54" translate>complience.kyc</span></a></em>
  </p>

  <p style="color:white; "><em><a href="https://www.lbma.org.uk/assets/market/gdl/RGG%20v6.0%20201508014.pdff"
                                  target="_blank"><span
    style="color:#9d8b54" translate>complience.lbma</span></a></em></p>

  <p style="color:white; "><em><a href="../../../assets/docs/GoldSupplement.pdf" target="_blank"><span
    style="color:#9d8b54" translate>complience.oecd</span></a></em></p>

  <p style="color:white; "><em><a
href="../../../assets/docs/Conflict_Free_Gold_Standard_English.pdf"
    target="_blank"><span style="color:#9d8b54" translate>complience.world</span></a></em></p>

<!-- fddg -->
 <!--  <p style="color:white; "><em><a href="https://masak.hmb.gov.tr/" target="_blank"><span
    style="color:#9d8b54" translate>complience.masak</span></a><br>
    <a href="http://www.borsaistanbul.com/" target="_blank"><span
      style="color:#9d8b54" translate>complience.borsa</span></a><br>
    <a href="../../../assets/docs/Bagimsiz-denetim-raporu.pdf"><span style="color:#9d8b54" translate>complience.bagimsiz</span></a><br>
  </em>
  </p> -->

<!-- fddg -->

<p style="color:white; "><em><a href="https://masak.hmb.gov.tr/"><span
    style="color:#9d8b54" translate>complience.masak</span></a></em>
</p>

<p style="color:white; "><em><a href="http://www.borsaistanbul.com/"><span
    style="color:#9d8b54" translate>complience.borsa</span></a></em>
</p>




<!-- burayı bozma -->
  <p style="color:white; "><em><a href="../../../assets/docs/Cerceve_Sozlesme.pdf"><span
    style="color:#9d8b54" translate>complience.cerceve</span></a></em>
  </p>

  <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/Musteri_Kabul_Beyani.pdf"><span
    style="color:#9d8b54" translate>complience.kabul</span></a></em>
  </p>
  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/ENGdeclaration.pdf"><span
    style="color:#9d8b54" translate>complience.kabul</span></a></em>
  </p>

  <p style="color:white; "><em><a href="../../../assets/docs/Musteri_Tani_Formu.pdf"><span
    style="color:#9d8b54" translate>complience.tani</span></a></em></p>

  <p style="color:white; "><em><a href="../../../assets/docs/Musteri_Yetki_Belgesi.pdf"><span
    style="color:#9d8b54" translate>complience.yetki</span></a></em></p>

  <p style="color:white; "><em><a href="../../../assets/docs/Cerez-Aydinlatma-Metni.pdf"><span
    style="color:#9d8b54" translate>complience.cerez</span></a></em></p>

  <p style="color:white; "><em><a href="../../../assets/docs/Verishabibi.pdf"><span
    style="color:#9d8b54" translate>complience.veri</span></a></em></p>

  <p style="color:white; "><em><a href="../../../assets/docs/kvk.pdf"><span
    style="color:#9d8b54" translate>complience.kvkk</span></a></em></p>

    



    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/Kontrol_Sistemi_Ve_Tedarik.pdf"><span
      style="color:#9d8b54" translate>complience.kontrol_sistemi</span></a></em>
    </p>
    <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/ENGinternalcontrol.pdf"><span
      style="color:#9d8b54" translate>complience.kontrol_sistemi</span></a></em>
    </p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/bagimsiz2022.pdf"><span
      style="color:#9d8b54" translate>complience.bagimsiz23</span></a></em>
    </p>
    <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/bagimsiz2022.pdf"><span
      style="color:#9d8b54" translate>complience.bagimsiz23</span></a></em>
    </p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/2022bagimsiztedarik.pdf"><span
      style="color:#9d8b54" translate>complience.guvence</span></a></em>
    </p>
    <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/2022bagimsiztedarik.pdf"><span
      style="color:#9d8b54" translate>complience.guvence</span></a></em>
    </p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/yonetim_raporu_2023.pdf"><span
      style="color:#9d8b54" translate>complience.yonetim2023</span></a></em>
    </p>
    <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/yonetim_raporu_2023.pdf"><span
      style="color:#9d8b54" translate>complience.yonetim2023</span></a></em>
    </p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/bagimsiz_denetim_raporu_2023.pdf"><span
      style="color:#9d8b54" translate>complience.denetim2023</span></a></em>
    </p>
    <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/bagimsiz_denetim_raporu_2023.pdf"><span
      style="color:#9d8b54" translate>complience.denetim2023</span></a></em>
    </p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="../../../assets/docs/bagimsiz_guvence_raporu_2023.pdf"><span
      style="color:#9d8b54" translate>complience.guvence2023</span></a></em>
    </p>
    <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/bagimsiz_guvence_raporu_2023.pdf"><span
      style="color:#9d8b54" translate>complience.guvence2023</span></a></em>
    </p>

    <!-- <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="../../../assets/docs/ENGKYC1.pdf"><span
      style="color:#9d8b54" translate>complience.kycform</span></a></em>
    </p> -->


 
        <!-- <p style="color:white; "><em><a href="../../../assets/docs/rona_bagımsız_guvence.pdf"><span
          style="color:#9d8b54" translate>complience.denetim</span></a></em></p> -->

       
</div>








